import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";

import AddressExplorer from "@/widgets/Landing/shared/AddressExplorer";
import ThemeButton from '@/shared/ui/ThemeButton';
import { useNavigation } from '@/processes/Router';

import s from "./WillBeTerminated.module.scss";


type WillBeTerminatedProps = {
  isAuth?: any;
}

const WillBeTerminated = (props: WillBeTerminatedProps) => {
  const { isAuth } = props;
  const { navigate, poolDashboardLink } = useNavigation();

  return (
    <div className={s.root}>
      <div className={s.content}>

        <h4 className={cx(s.title, s.titleBig)}>
          GRAM pool will be terminated on Aug 30, 2024
        </h4>

        <h4 className={s.title}>
          What should I do?
        </h4>

        <ol className={s.ol}>
          <li className={s.listItem}>
            Switch your mining devices to another pool before August 30th, 23:59 CET. You can compare and choose the
            optimal pool for you at
            <a className={s.link} target="_blank" rel="noopener noreferrer"
               href="https://miningpoolstats.stream/"> Mining Pool Stats</a> and continue managing
            your devices in Hive OS.

            <h4 className={cx(s.title, 'mt-3')}>
              How to switch:
            </h4>

            <ul className={s.ul}>
              <li className={s.listItem}>Click on the 3 dots next to your existing flight sheet →</li>
              <li className={s.listItem}>Click edit →</li>
              <li className={s.listItem}>Under the pool field click on the drop-down arrow →</li>
              <li className={s.listItem}>Choose any pool in the list →</li>
              <li className={s.listItem}>Select the closest server(s) and click apply → Click Update</li>
            </ul>

            <b className="inline-block mt-2 leading-6">
              For non-Hive OS users:
            </b>
            <p>
              Make sure you&apos;ve added a wallet to receive the final payout by clicking ‘Dashboard’ below and
              then &apos;Set up&apos; in the right upper corner
            </p>

          </li>
          <li className={s.listItem}>
            Shares from devices will cease to be accepted on August 30th at 23:59 CET. Payments will be made in full
            automatically to your wallet by August 31th, 23:59 CET. The minimum balance should exceed the network
            commission to ensure you receive a net positive amount.
          </li>
          <li className={s.listItem}>
            Any questions? We are here to help: <a className={s.link} href="mailto:bee@hiveon.com">bee@hiveon.com</a> or
            Live chat
            on <a className={s.link} href="http://hiveon.com/">hiveon.com</a>
          </li>
        </ol>

        {!isAuth ? (
          <AddressExplorer className="mt-3"/>
        ) : (
          <ThemeButton className="mt-3" onClick={() => {
            navigate(poolDashboardLink);
          }}>
            <FormattedMessage id="dashboard"/>
          </ThemeButton>
        )}
      </div>
    </div>
  );
};

export default WillBeTerminated;