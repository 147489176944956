import React from "react";
import WillBeTerminated from '@/widgets/Landing/gram/components/BannerTerminated/ui/WillBeTerminated';
import TerminatedDone from '@/widgets/Landing/gram/components/BannerTerminated/ui/TerminatedDone';

type BannerTerminatedProps = {
  isAuth?: any;
  isTerminated: boolean;
}

const BannerTerminated = (props: BannerTerminatedProps) => {
  const { isAuth, isTerminated } = props;

  return (isTerminated ? <TerminatedDone /> : <WillBeTerminated isAuth={isAuth} />);
};

export default BannerTerminated;