import Intro from  "@/widgets/Landing/accounting/Intro";
import Support from "@/widgets/Landing/shared/Support";
import StartMiningWith from "@/widgets/Landing/shared/StartMiningWith";
import ProfitCalculator from "~/ProfitCalculator";
import JoinSection from "@/widgets/Landing/shared/JoinSection";
import HowToGetHiveonOs from "@/widgets/Landing/shared/HowToGetHiveonOs";
import Advantages from "@/widgets/Landing/gram/components/Advantages";
import QuickStats from "@/widgets/Landing/gram/components/QuickStats";
import MinerBlocks from "@/widgets/Landing/gram/components/MinerBlocks";
import AddressExplorer from "@/widgets/Landing/shared/AddressExplorer";
import { useMinerAccount } from '@/processes/MinerAccounts';
import BannerTerminated from '@/widgets/Landing/gram/components/BannerTerminated';


const LandingBlocks = () => {
  const { isAuth } = useMinerAccount();
  return (
    <>
      <BannerTerminated isAuth={isAuth} isTerminated />

      <Intro className={isAuth ? 'py-20 md:py-32' : 'pt-20 md:pt-32'} titleKey="l.gram.title" descKey="landing.gram.intro.desc"/>
      {!isAuth ? <AddressExplorer className={'my-6 sm:my-8'}/> : null}
      <ProfitCalculator className="my-6 sm:my-8 sm:mb-32"/>
      <QuickStats className="my-6 sm:my-8"/>
      <MinerBlocks className="my-6 md:my-8"/>
      <Advantages className='my-20 md:my-40 md:mb-20'/>
      <Support className='my-20 md:my-40 md:mt-20'/>
      <StartMiningWith className='my-20 md:my-40'/>
      <HowToGetHiveonOs className='my-20 md:my-40'/>
      <JoinSection titleKey="footer.join.title.v2" className='pb-8 my-20 md:mb-20 md:mt-40'/>
    </>
  );
}

export default LandingBlocks;
