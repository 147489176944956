import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";

import s from "./TerminatedDone.module.scss";


const TerminatedDone = () => {
  return (
    <div className={s.root}>
      <div className={s.content}>

        <h4 className={cx(s.title, s.titleBig)}>
          Gram Pool is terminated
        </h4>

        <h4 className={s.title}>
          All mined coins were paid out to the wallets indicated in your Flight Sheets.
        </h4>

        <h4 className={s.title}>
          See you in Hive OS 🧡
        </h4>

      </div>
    </div>
  );
};

export default TerminatedDone;